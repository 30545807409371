/**
*  --------------------------------
*  project/BASE
*  --------------------------------
*/

@import 'config/index.scss';

$theme: 'bcf';
$backgroundColor: $white;
$secondaryColor: $grayMedium;
$color: $black;
$accentColor: #0e4c92;
$imageSlideGradient: linear-gradient(to bottom, rgba(darken(#002751, 10%),0.4) 0%, rgba(darken(#002751, 10%), 0) 40%, rgba(darken(#002751, 10%), 0) 60%, rgba(darken(#002751, 10%),0.4) 100%);
$navBorder: 1px solid rgba(0,0,0,0.1);
$navBorderInvert: 1px solid rgba($borderInvert, .15);

@import 'main.scss';
